<template>
  <v-card>
    <v-card-subtitle>
      <!-- display alert -->
      <base-alert
        v-model="showAlert"
        :dismissible="isAlertDismissible"
        :type="currentAlertType"
      >
        <span v-html="alertMessage"></span>
      </base-alert>
    </v-card-subtitle>
    <v-card-text>
      <v-textarea
        :rows="10"
        clearable
        :label="label"
        v-model="text"
        outlined
      ></v-textarea>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <!-- Button Save -->
      <base-button :disabled="!enabledCommandSave" @click="saveNote"
        >Save</base-button
      >
      <!-- Button Delete-->
      <base-button :disabled="!enabledCommandDelete" @click="deleteNote"
        >Delete</base-button
      >
    </v-card-actions>

    <!-- delete Legislator Note Dialog -->
    <delete-legislator-note-dialog
      :record="record"
      :visible="visibleDeleteNoteDialog"
      :perform-action="onDeleteNote"
      @close="closeDeleteNoteDialog"
    ></delete-legislator-note-dialog>
  </v-card>
</template>

<script>
import { displayTypes, fontEmphasis, Text } from "@/design/text/Text";
import {
  Color,
  colorMD,
  variantNumber,
  variantType
} from "@/design/colors/Color";
import { recordType } from "@/model/record/recordModel";
import { iconAgendaItem, iconAgendaSection } from "@/design/icon/iconConst";

// vuex store
import { createNamespacedHelpers } from "vuex";
import { alertableMixin } from "@/mixins/shared/alertable/alertableMixin";

const { mapActions } = createNamespacedHelpers("agenda");

export default {
  name: "LegislatorNotepadCard",
  mixins: [alertableMixin],
  components: {
    DeleteLegislatorNoteDialog: () =>
      import("@/components/agenda/dialogs/DeleteLegislatorNoteDialog"),
    BaseButton: () => import("@/components/shared/base/BaseButton.vue")
  },
  props: {
    record: undefined
  },
  data() {
    return {
      text: "",
      note: undefined,
      titleTextClass: new Text(
        new Color(colorMD.indigo, variantType.lighten, variantNumber.n1),
        displayTypes.title,
        fontEmphasis.bold
      ).getClassText(),
      visibleDeleteNoteDialog: false
    };
  },
  computed: {
    /**
     * Determine which icon to display based on whether it's an agenda item or section.
     * @returns {string} Icon path
     */
    icon() {
      return this.isAgendaItem ? iconAgendaItem : iconAgendaSection;
    },

    /**
     * Check if the current record is an agenda item.
     * @returns {boolean}
     */
    isAgendaItem() {
      return (
        (this?.record?.recordTypeId ?? recordType.RECORD) === recordType.ITEM
      );
    },

    /**
     * Get the title for the component, which is the name of the record or an empty string.
     * @returns {string}
     */
    title() {
      return this.record?.name ?? "";
    },

    /**
     * Get the label for the component, which is always "Legislator Notepad".
     * @returns {string}
     */
    label() {
      return "Legislator Notepad";
    },

    /**
     * Get the ID of the current record or a default value of -1 if not available.
     * @returns {number}
     */
    recordId() {
      return this.record?.id ?? -1;
    },

    /**
     * Determine if the save command is enabled based on whether there is text to save.
     * @returns {boolean}
     */
    enabledCommandSave() {
      return !!this.text;
    },

    /**
     * Determine if the delete command is enabled based on whether there is a note with text to delete.
     * @returns {boolean}
     */
    enabledCommandDelete() {
      return !!this.note?.text;
    }
  },

  methods: {
    /**
     * Map Vuex actions to component methods.
     */
    ...mapActions({
      getLegislatorNote: "getLegislatorNote",
      saveLegislatorNote: "saveLegislatorNote",
      deleteLegislatorNote: "deleteLegislatorNote"
    }),

    /**
     * Fetch the legislator note for the current record and populate the component.
     */
    async getNote() {
      try {
        this.note = await this.getLegislatorNote(this.record);
        this.text = this.note?.text ?? "";
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError("Get Note", e),
          false
        );
      }
    },

    /**
     * Save the legislator note with the current text content.
     */
    async saveNote() {
      try {
        const payload = {
          record: this.record,
          note: this.text
        };
        this.note = await this.saveLegislatorNote(payload);
        this.internalAlert = this.createAlert(
          this.alertTypeName.success,
          "Note Saved Successfully",
          true
        );
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError("Save Note", e),
          false
        );
      }
    },

    /**
     * Trigger the delete note dialog to be displayed.
     */
    deleteNote() {
      this.visibleDeleteNoteDialog = true;
    },

    /**
     * Delete the legislator note associated with the current record.
     */
    async onDeleteNote() {
      try {
        this.result = await this.deleteLegislatorNote(this.record);
        if (this.result) {
          this.text = this.result ? "" : this.text;
          this.note = undefined;
        } else {
          this.internalAlert = this.createAlert(
            this.alertTypeName.error,
            this.formatAlertError("Delete Note", "Error"),
            false
          );
        }
      } catch (e) {
        this.internalAlert = this.createAlert(
          this.alertTypeName.error,
          this.formatAlertError("Delete Note", e),
          false
        );
      }
    },

    /**
     * Close the delete note dialog.
     */
    closeDeleteNoteDialog() {
      this.visibleDeleteNoteDialog = false;
    }
  },

  async mounted() {
    // Fetch the legislator note when the component is mounted.
    await this.getNote();
  },

  watch: {
    /**
     * Watch for changes in the "record" property and fetch the updated note when it changes.
     */
    async record() {
      await this.getNote();
    }
  }
};
</script>

<style scoped></style>
